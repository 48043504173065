/**
 * Payments Portal API
 * This specifies the Payment endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class PaymethodStripeConnectDetailsStripeConnect {
    'accountId': string;
    'email'?: string;
    'origin'?: string;
    'redirectUrl'?: string;
    'displayDetails'?: string;
    'payoutsEnabled'?: string;
    'chargesEnabled'?: string;
    'cardPaymentsEnabled'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "accountId",
            "baseName": "accountId",
            "type": "string"
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string"
        },
        {
            "name": "origin",
            "baseName": "origin",
            "type": "string"
        },
        {
            "name": "redirectUrl",
            "baseName": "redirectUrl",
            "type": "string"
        },
        {
            "name": "displayDetails",
            "baseName": "displayDetails",
            "type": "string"
        },
        {
            "name": "payoutsEnabled",
            "baseName": "payoutsEnabled",
            "type": "string"
        },
        {
            "name": "chargesEnabled",
            "baseName": "chargesEnabled",
            "type": "string"
        },
        {
            "name": "cardPaymentsEnabled",
            "baseName": "cardPaymentsEnabled",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return PaymethodStripeConnectDetailsStripeConnect.attributeTypeMap;
    }
}

