/**
 * Assets Portal API
 * This specifies the Asset endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { AssetMetadataAttribute } from './assetMetadataAttribute';
import type { AssetMetadataProperty } from './assetMetadataProperty';

export class AssetMetadata {
    'name'?: string;
    'description'?: string;
    'image'?: string;
    'supportingImages'?: Array<string>;
    'attributes'?: { [key: string]: AssetMetadataAttribute; };
    'properties'?: { [key: string]: AssetMetadataProperty; };

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "string"
        },
        {
            "name": "supportingImages",
            "baseName": "supportingImages",
            "type": "Array<string>"
        },
        {
            "name": "attributes",
            "baseName": "attributes",
            "type": "{ [key: string]: AssetMetadataAttribute; }"
        },
        {
            "name": "properties",
            "baseName": "properties",
            "type": "{ [key: string]: AssetMetadataProperty; }"
        }    ];

    static getAttributeTypeMap() {
        return AssetMetadata.attributeTypeMap;
    }
}

