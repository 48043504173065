/**
 * Listings Portal API
 * This specifies the Listing endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class CheckoutSessionRequest {
    /**
    * The unique id for the listing
    */
    'listingId': string;
    /**
    * The unique id for the payer\'s payment method
    */
    'payerPaymentMethodId'?: string;
    /**
    * The unique id for the recipient\'s wallet to transfer the asset(s) to
    */
    'recipientWalletId': string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "listingId",
            "baseName": "listingId",
            "type": "string"
        },
        {
            "name": "payerPaymentMethodId",
            "baseName": "payerPaymentMethodId",
            "type": "string"
        },
        {
            "name": "recipientWalletId",
            "baseName": "recipientWalletId",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return CheckoutSessionRequest.attributeTypeMap;
    }
}

