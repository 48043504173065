// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment, LogLevel } from './env-type';

export const environment: Environment = {
    stage: 'dev',
    production: false,
    logLevel: LogLevel.Debug,
    // Dev-Portal API uses a different endpoint when run locally. Note: Dev-Portal API must be started separately from the PMS server.
    appName: 'Dev-Portal',
    authenticationUrl: 'https://dev-api.authentication.dev.fsco.io/api/',
    uiUrl: 'https://dashboard.dev.fsco.io',
    apiBaseUrl: 'https://dev.api.fsco.io/portal-api',
    sentryUrl: 'https://f168d7a1b46d4b6499452e9884ef2e65@o451791.ingest.sentry.io/5438316',
    clientKey: '10559d98-cb30-4648-b65a-e0f652adcaaa',
    clientId: 'f71865bd-6f9a-4408-b21e-bf735c1f4e05',
    webSocket: {
        url: 'https://websocket.dev.api.fsco.io',
        reconnection: {
            attempt: 10,
            delay: 10000,
            interval: 30000,
        },
    },
    networkUrls: {
        hedera: 'https://explore.lworks.io/testnet/',
    },
    documents: {
        groups: {
            defaultTypeId: '4d95d4d7-866b-4245-a496-188bee70943b',
        },
    },
    marketplace: {
        identifiers: {
            mtn: '00000000-0000-0000-0000-000000000001',
        },
        currencies: [
            { display: 'AUD', value: 'AUD', address: '', type: 'fiat' },
            { display: 'USD', value: 'USD', address: '', type: 'fiat' },
            { display: 'EUR', value: 'EUR', address: '', type: 'fiat' },
            { display: 'GBP', value: 'GBP', address: '', type: 'fiat' },
            { display: 'PHP', value: 'PHP', address: '', type: 'fiat' },
            { display: 'JPY', value: 'JPY', address: '', type: 'fiat' },
            { display: 'CNY', value: 'CNY', address: '', type: 'fiat' },
            { display: 'SGD', value: 'SGD', address: '', type: 'fiat' },
            { display: 'QAR', value: 'QAR', address: '', type: 'fiat' },
        ],
    },
    payment: {
        stripePayments: {
            paymethodId: '8fe454af-083a-4764-84aa-91b385278804',
        },
    },
    loadHotjar: false,
    featureWallets: true,
};
