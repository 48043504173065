/**
 * Wallets Portal API
 * This specifies the Wallet endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class WalletDeploymentBalance {
    'balance': string;
    'decimals'?: string;
    'walletAddress': string;
    'contractDeploymentId': string;
    'chainId': string;
    'abiName': string;
    'deploymentName': string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "balance",
            "baseName": "balance",
            "type": "string"
        },
        {
            "name": "decimals",
            "baseName": "decimals",
            "type": "string"
        },
        {
            "name": "walletAddress",
            "baseName": "walletAddress",
            "type": "string"
        },
        {
            "name": "contractDeploymentId",
            "baseName": "contractDeploymentId",
            "type": "string"
        },
        {
            "name": "chainId",
            "baseName": "chainId",
            "type": "string"
        },
        {
            "name": "abiName",
            "baseName": "abiName",
            "type": "string"
        },
        {
            "name": "deploymentName",
            "baseName": "deploymentName",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return WalletDeploymentBalance.attributeTypeMap;
    }
}

