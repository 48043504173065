/**
 * Payments Portal API
 * This specifies the Payment endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { PaymethodMtnDetails } from './paymethodMtnDetails';
import type { PaymethodMtnDetailsMtn } from './paymethodMtnDetailsMtn';
import type { PaymethodStripeConnectDetails } from './paymethodStripeConnectDetails';
import type { PaymethodStripeConnectDetailsStripeConnect } from './paymethodStripeConnectDetailsStripeConnect';
import type { PaymethodStripePaymentsDetails } from './paymethodStripePaymentsDetails';
import type { PaymethodStripePaymentsDetailsStripePayments } from './paymethodStripePaymentsDetailsStripePayments';

export class PaymethodDetails {
    'mtn': PaymethodMtnDetailsMtn;
    'stripe_connect': PaymethodStripeConnectDetailsStripeConnect;
    'stripe_payments': PaymethodStripePaymentsDetailsStripePayments;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "mtn",
            "baseName": "mtn",
            "type": "PaymethodMtnDetailsMtn"
        },
        {
            "name": "stripe_connect",
            "baseName": "stripe_connect",
            "type": "PaymethodStripeConnectDetailsStripeConnect"
        },
        {
            "name": "stripe_payments",
            "baseName": "stripe_payments",
            "type": "PaymethodStripePaymentsDetailsStripePayments"
        }    ];

    static getAttributeTypeMap() {
        return PaymethodDetails.attributeTypeMap;
    }
}

