/**
 * Webhooks Outbound Portal API
 * This specifies the Webhook Outbound endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { WebhookOutboundInvokeStatus } from './webhookOutboundInvokeStatus';

export class WebhookOutboundLog {
    'id': string;
    'url'?: string;
    'content'?: { [key: string]: any; };
    'status'?: WebhookOutboundInvokeStatus;
    'response'?: string;
    'error_message'?: string;
    'createdAt'?: Date;
    'webhookRequestId': string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "{ [key: string]: any; }"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "WebhookOutboundInvokeStatus"
        },
        {
            "name": "response",
            "baseName": "response",
            "type": "string"
        },
        {
            "name": "error_message",
            "baseName": "error_message",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date"
        },
        {
            "name": "webhookRequestId",
            "baseName": "webhookRequestId",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return WebhookOutboundLog.attributeTypeMap;
    }
}

export namespace WebhookOutboundLog {
}
