/**
 * Webhooks Outbound Portal API
 * This specifies the Webhook Outbound endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class WebhookOutbound {
    'id': string;
    'name'?: string;
    'description'?: string;
    'enabledEvents'?: Array<string>;
    'organisationId': string;
    'status': WebhookOutbound.StatusEnum;
    'url': string;
    'createdAt'?: Date;
    'lastTriggeredAt'?: Date;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "enabledEvents",
            "baseName": "enabledEvents",
            "type": "Array<string>"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "WebhookOutbound.StatusEnum"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date"
        },
        {
            "name": "lastTriggeredAt",
            "baseName": "lastTriggeredAt",
            "type": "Date"
        }    ];

    static getAttributeTypeMap() {
        return WebhookOutbound.attributeTypeMap;
    }
}

export namespace WebhookOutbound {
    export enum StatusEnum {
        Active = <any> 'active',
        Paused = <any> 'paused',
        Disabled = <any> 'disabled'
    }
}
