/**
 * Payments Portal API
 * This specifies the Payment endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class PaymethodStripePaymentsDetailsStripePayments {
    'receiverPaymethodId': string;
    'customerId'?: string;
    'paymentIntentId': string;
    'paymentMethodId'?: string;
    'displayDetails'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "receiverPaymethodId",
            "baseName": "receiverPaymethodId",
            "type": "string"
        },
        {
            "name": "customerId",
            "baseName": "customerId",
            "type": "string"
        },
        {
            "name": "paymentIntentId",
            "baseName": "paymentIntentId",
            "type": "string"
        },
        {
            "name": "paymentMethodId",
            "baseName": "paymentMethodId",
            "type": "string"
        },
        {
            "name": "displayDetails",
            "baseName": "displayDetails",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return PaymethodStripePaymentsDetailsStripePayments.attributeTypeMap;
    }
}

